import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@nubeteck/components";
import { UserHelp } from "../../other";
import { LogoCenfotecBlancoPNG } from "../../../assets";
import {
  HeaderStyled,
  HomeLogo,
  MenuStyled,
  MobileMenuButtonStyled,
  UserSectionStyled,
  HeaderText,
  DropdownContainerStyled,
  DropdownLinkStyled,
} from "./header.styled";
import { IMenuItem } from "src/Interfaces/options";
import { useMediaQuery } from "src/hooks";
import { Dropdown, Space } from "antd";
import { MenuProps } from "antd/lib";
import { useSelector } from "react-redux";
import { RootState } from "@/core/store/store";

interface IHeader {
  menuItems: (IMenuItem | null)[];
  userName: string;
  openMenu: () => void;
  isHelpVisible: boolean;
  isFakeSelecting: boolean;
  logout: () => void;
  logoutImpersonated: () => void;
}

const UserSection = ({
  userName,
  logout,
  isHelpVisible,
  isAdmin,
  availableRoles,
  isATeacher,
}: {
  userName: string;
  logout: () => void;
  isHelpVisible: boolean;
  isAdmin: boolean;
  isATeacher: boolean;
  availableRoles: MenuProps;
}) => (
  <UserSectionStyled>
    {!isAdmin && <>{/* <Notifications /> */}</>}
    {isHelpVisible && <UserHelp />}
    <DropdownContainerStyled>
      <HeaderText
        style={{
          maxWidth: "145px",
          display: "block",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {userName}
      </HeaderText>
      <Dropdown menu={availableRoles} trigger={["hover"]}>
        <DropdownLinkStyled>
          <Space>
            {isATeacher ? "Docente" : isAdmin ? "Admin" : "Estudiante"}
            <Icon name="" />
          </Space>
        </DropdownLinkStyled>
      </Dropdown>
    </DropdownContainerStyled>
    <Icon isTouchable={true} name="power_settings_new" color="white" onClick={logout} />
  </UserSectionStyled>
);

const Header = ({
  menuItems,
  userName,
  openMenu,
  isHelpVisible,
  isFakeSelecting,
  logout,
  logoutImpersonated,
}: IHeader) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isASmallMobile = useMediaQuery("(max-width: 480px)");
  const profiles = localStorage.getItem("profiles")
    ? JSON.parse(localStorage.getItem("profiles") ?? "")
    : {};
  const { isATeacher } = useSelector((state: RootState) => state.user);
  const { isAdmin } = useSelector((state: RootState) => state.user);

  const rolesItems: MenuProps["items"] = Object?.keys(profiles)?.map((pro) => ({
    key: pro,
    label: pro,
    onClick: () => {
      localStorage.setItem("token", profiles[pro]);
      navigate("/");
      window.location.reload();
    },
  }));

  return (
    <HeaderStyled title="Cenfotec Autoservicio">
      <HomeLogo
        src={LogoCenfotecBlancoPNG}
        width={75}
        preview={false}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      />
      <HeaderText>AUTOSERVICIO {isATeacher && !isASmallMobile ? "DOCENTES" : null}</HeaderText>
      <MenuStyled>
        {menuItems.map(
          (item) =>
            item && (
              <li key={item.name}>
                <NavLink
                  title={item.tooltip}
                  onClick={
                    isFakeSelecting
                      ? () => {
                          logoutImpersonated();
                          navigate(item.link);
                          window.location.reload();
                        }
                      : () => null
                  }
                  to={item.link}
                  end
                  className={
                    item?.exactLink
                      ? location.pathname.split("/")[1] === item.exactLink
                        ? "is-active"
                        : ""
                      : ({ isActive }) => (isActive ? "is-active" : "")
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ),
        )}
      </MenuStyled>
      <UserSection
        userName={userName}
        logout={logout}
        isHelpVisible={isHelpVisible}
        isAdmin={isAdmin}
        isATeacher={isATeacher}
        availableRoles={{ items: rolesItems }}
      />
      <MobileMenuButtonStyled type="text" onClick={openMenu}>
        <Icon name="" size={32} />
      </MobileMenuButtonStyled>
    </HeaderStyled>
  );
};

export default Header;
